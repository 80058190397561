import { Logger } from "@hackler/sdk-core"
import { SDK_NAME_HEADER, SDK_VERSION_HEADER } from "./config"

const log = Logger.log

export interface Response {
  statusCode?: number
  body: string
}

export interface Request {
  abort(): void
  responsePromise: Promise<Response>
}

export interface Headers {
  [header: string]: string
}

export function getRequest(requestUrl: string, headers: Headers): Request {
  const responsePromise = new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open("GET", requestUrl)

    xhr.onreadystatechange = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status >= 200 && xhr.status < 400) {
          resolve({
            statusCode: xhr.status,
            body: xhr.responseText,
          })
        }
      }
    }

    xhr.setRequestHeader("content-type", "application/json")
    xhr.withCredentials = true
    setHeaders(xhr, headers)
    xhr.send()
  })

  return {
    abort(): void {
      //request.abort()
    },
    responsePromise,
  }
}

export function postRequest(requestUrl: string, data: any, headers: Headers, callback: any) {
  const dataString = JSON.stringify(data)

  const xhr = new XMLHttpRequest()
  xhr.open("POST", requestUrl)
  xhr.onreadystatechange = function () {
    if (xhr.readyState === xhr.DONE) {
      if (xhr.status >= 200 && xhr.status < 400) {
        callback(xhr)
      }
    }
  }

  xhr.setRequestHeader("content-type", "application/json")
  xhr.withCredentials = true
  setHeaders(xhr, headers)
  xhr.send(dataString)
}

function setHeaders(xhr: XMLHttpRequest, headers: Headers) {
  for (const name in headers) {
    if (headers.hasOwnProperty(name)) {
      xhr.setRequestHeader(name, headers[name])
    }
  }
}

export function sendBeacon(requestUrl: string, data: any, headers: Headers, callback: any) {
  let beaconSupport = false

  // @ts-ignore
  if ((typeof window) !== "undefined") {
    // @ts-ignore
    beaconSupport = window && window.navigator && window.navigator.sendBeacon && true
  }

  if (beaconSupport) {
    const dataString = JSON.stringify(data)

    const params = new URLSearchParams()
    params.append("sdkName", headers[SDK_NAME_HEADER])
    params.append("sdkVersion", headers[SDK_VERSION_HEADER])
    params.append("data", dataString)

    window.navigator.sendBeacon(requestUrl, params)
    callback()
  } else {
    log.error("Does not support sendBeacon")
    callback()
  }
}
