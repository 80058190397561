export default class Cookie {
  static get(name: string): string|null {
    const nameEQ = name + "="

    // @ts-ignore
    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(nameEQ) === 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length))
      }
    }
    return null
  }

  static set(name: string, value: string, days?: number, is_cross_subdomain?: boolean, is_secure?: boolean, is_cross_site?: boolean) {
    let cdomain = "", expires = "", secure = ""

    if (is_cross_subdomain) {
      // @ts-ignore
      const domain = extractDomain(document.location.hostname)
      cdomain = domain ? "; domain=." + domain : ""
    }

    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = "; expires=" + date.toUTCString()
    }

    if (is_cross_site) {
      is_secure = true;
      secure = '; SameSite=None';
    }
    if (is_secure) {
      secure += '; secure';
    }

    const new_cookie_val = name + "=" + encodeURIComponent(value) + expires + "; path=/" + cdomain + secure

    // @ts-ignore
    document.cookie = new_cookie_val
    return new_cookie_val
  }

  static remove(name: string, is_cross_subdomain?: boolean) {
    Cookie.set(name, '', -1, is_cross_subdomain, false, false);
  }
}

export function extractDomain(hostname: string): string {
  const DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]+\.[a-z.]{2,6}$/i
  const SIMPLE_DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]*\.[a-z]+$/i

  let domain_regex = DOMAIN_MATCH_REGEX

  const parts = hostname.split(".")
  const tld = parts[parts.length - 1]
  if (tld.length > 4 || tld === "com" || tld === "org") {
    domain_regex = SIMPLE_DOMAIN_MATCH_REGEX
  }

  const matches = hostname.match(domain_regex)
  return matches ? matches[0] : ""
}
